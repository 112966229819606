import React, { useState, useEffect } from 'react';
import {
  Button,
  Header,
  Modal,
  Rating,
  Form,
  TextArea,
  Message,
  Radio,
} from "semantic-ui-react";
import Cookies from "universal-cookie";
import { sendFeedback } from "../actions/searchActions";
import {gaRecommendationSkipped, gaRecommendationSubmitted} from "../actions/gaevents";



const GeneralMessage = () => {
    const [openModal, setOpenModal] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {
      let storedCookies = cookies.getAll();

   
      if(storedCookies.genmessage) 
      {
        if (storedCookies.genmessage == "AlreadyRead" )
        setOpenModal(false);
        else
        setOpenModal(true);
      }
      else
      
      {
         cookies.set("genmessage", "AlreadyRead",  {path: "/",  maxAge: 31536000});
          setOpenModal(true);
      }
     
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
 const submitFeedback = () => {
     
     
     
 

   // gaRecommendationSubmitted(rating , email ) ;
  };
    // function setCookieValue(key: any, value: any) {
    //   profileArray[key] = value;
    // };

    const handleSubmit = (skip : boolean) => {
      
   

    if(skip)
   {
     //  gaRecommendationSkipped();
     //  cookies.set("firstvisit", "skipped",  {path: "/",  maxAge: 31536000});
   }
   else
   {
    // submitFeedback();
     //cookies.set("firstvisit", "Submitted",  {path: "/",  maxAge: 31536000});
   }
   
   
   setOpenModal(false);


    }

    return (
        <Modal
            onClose={() => setOpenModal(false)}
            open={openModal}
            dimmer={'blurring'}
            className='profile-modal'
        >
        <Modal.Header>Servulink Digital Hub</Modal.Header>
         <Form style={{ marginTop: 5, marginLeft : 20 ,marginRight : 15 }}>

         <Modal.Header as= "h5"  size = 'tiny' ><br/>  As the Royal Commission into Defence and Veteran Suicide prepares to make its final report in September, Servulink continues to empower our veterans and their families to find the services and support they need, when they need it. 
         <br/>  <br/> From now through September (and beyond) please share this free digital hub broadly across the veteran community.<br/><br/> Thank you so much.<br/> <br/>Ian and Matt,<br/> Co-founders Servulink</Modal.Header>
      
         
         
         </Form>
      <Modal.Content>
      </Modal.Content>
            <Modal.Actions>
               
                <Button
                content="Close"
//                labelPosition='middle'
  //              icon='none'
                onClick={() => handleSubmit(false)}
                primary
                />
            </Modal.Actions>
        </Modal>
    )
}

export default GeneralMessage
