import React, { useEffect, useState } from "react";
import HeaderComponent from "./Header.component";
import { MapComponent } from "./Map.component";
import Recommend  from './Recommend.component';
import GeneralMessage from "./GeneralMessage.component";
import ProfileModal from "./ProfileModal.component";
import CookieConsent from "react-cookie-consent";
import Cookies from "universal-cookie";

export const HomeComponent = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    let isCookieAccepted = cookies.get("CookieConsent");
    console.log({ isCookieAccepted });
    if (isCookieAccepted) {
      setCookieAccepted(true);
    }
  }, []);

  return (
    <div>
      <div>
        <HeaderComponent />
        <MapComponent />
        {cookieAccepted && <GeneralMessage />}
      </div>
      <CookieConsent
        overlay
        location="bottom"
        cookieName="CookieConsent"
        onAccept={() => setCookieAccepted(true)}
        expires={30}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
  );
};
